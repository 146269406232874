<style>
  div {
    width: 400px;
    padding: 20px 30px;
    box-sizing: border-box;
    line-height: 25px;
  }
</style>

<div>Instructions are gonna go here. For now, <a href="/instructions.html" target="_blank"> you can view them at this link.</a></div>
